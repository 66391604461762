import styled from '@emotion/styled';
import { useMediaQuery } from '@react-hookz/web';

import { screen } from '@/components/common/breakpoints';
import { FeatureCard } from '@/components/common/FeatureCard';
import { Swiper } from '@/components/common/Swiper';
import { Col, Row } from '@/components/layout/Grid';
import { Section } from '@/components/layout/Section';

import type { SwiperProps } from '@/components/common/Swiper';
import type { SanityImageAsset, SanityMasonryGridType } from '@/types/sanity';

interface MasonryGridProps extends Omit<SanityMasonryGridType, 'featureCards'> {
  featureCards: (Omit<
    SanityMasonryGridType['featureCards'][number],
    'responsiveImage'
  > & {
    responsiveImage: Omit<
      SanityMasonryGridType['featureCards'][number]['responsiveImage'],
      'images'
    > & {
      images: {
        image: { _type: 'image'; asset: SanityImageAsset };
        breakpoint: 'base' | 'sm' | 'md' | 'lg' | 'xl';
        align: 'center' | 'left' | 'right';
      }[];
    };
  })[];
}

const untangle = (arr: React.ReactNode[]) => ({
  odd: arr.filter((_, index) => (index + 1) % 2 === 1),
  even: arr.filter((_, index) => (index + 1) % 2 === 0),
});

export const MasonryGrid = ({
  blockSpacing,
  backgroundColour,
  featureCards,
  paddingAdjustment,
}: MasonryGridProps) => {
  const cards = featureCards.map((card) => (
    <FeatureCard
      key={card._key}
      title={card.title}
      surfaceColour={card.surfaceColour?.token}
      textAlign={card.textAlign}
      textPosition={card.textPosition}
      responsiveImage={card.responsiveImage}
      containerSize={{ base: '590px', sm: '710px', md: '520px' }}
    />
  ));

  const isDesktop = useMediaQuery(screen.mdQuery, {
    initializeWithValue: false,
  });

  if (isDesktop) {
    const { odd, even } = untangle(cards);

    return (
      <Section
        spacing={blockSpacing}
        backgroundColour={backgroundColour}
        verticalPadding={paddingAdjustment}
      >
        <Row>
          <Col sizes={{ lg: 5, md: 6 }} start={{ lg: 2 }}>
            <InnerColumn>{odd}</InnerColumn>
          </Col>
          <Col sizes={{ lg: 5, md: 6 }}>
            <InnerColumn>{even}</InnerColumn>
          </Col>
        </Row>
      </Section>
    );
  }

  const swiperSharedProps: SwiperProps = {
    slides: cards,
    autoplay: {
      enabled: true,
      showProgress: true,
      speed: 10000,
    },
    pagination: {
      enabled: true,
      type: 'simple',
    },
  };

  return (
    <Section
      spacing={blockSpacing}
      backgroundColour={backgroundColour}
      verticalPadding={paddingAdjustment}
    >
      <Row>
        <Col sizes={{ sm: 10 }} start={{ sm: 2 }}>
          <CarouselWrapper>
            <Swiper
              {...swiperSharedProps}
              effect="slide"
              spaceBetween="--space-component-lg"
            />
          </CarouselWrapper>
        </Col>
      </Row>
    </Section>
  );
};

const InnerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-block-md);
`;

const CarouselWrapper = styled.div`
  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    height: unset;
  }
`;
